import React, { useEffect } from "react";
import "./index.css";
import LoginPage from "./pages/Login/Login.page";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./Layouts/Main.Layout";
import LandingPage from "./pages/Landing/Landing.page";

export default function App() {
  // const navigate = useNavigate();

  useEffect(() => {
    if (
      (isLogged() == "false" || !isLogged()) &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/"
    ) {
      window.location.replace("/");
    }

    if (isLogged() == "true" && window.location.pathname !== "/dashboard") {
      window.location.replace("/dashboard");
    }
  }, []);

  function isLogged() {
    return localStorage.getItem("isLogged");
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={<MainLayout />} />
      </Routes>
    </BrowserRouter>
  );
}
