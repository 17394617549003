import * as React from 'react';
import Box from '@mui/material/Box';
import { LineChart, PieChart } from '@mui/x-charts';
import { Divider, Typography, FormControlLabel, Checkbox } from '@mui/material';
import moment from 'moment/moment';

export default function CustomBarChart({ data }) {
    // Estado para controlar a visibilidade das linhas
    const [visibleLines, setVisibleLines] = React.useState({
        faturamento: true,
        ticketMedio: true,
        quantidade: true,
    });

    // Função para alternar a visibilidade de uma linha
    const toggleLineVisibility = (line) => {
        setVisibleLines(prevState => ({
            ...prevState,
            [line]: !prevState[line],
        }));
    };

    // Extração de dados para os gráficos
    const series = [
        {
            label: 'Faturamento R$',
            showMark: false,
            data: data?.pedidos?.map(item => item.total) || [],
            color: '#FF6347', // Cor para Faturamento
            area: true,
            visible: visibleLines.faturamento,
        },
        {
            label: 'Ticket Médio R$',
            showMark: false,
            data: data?.pedidos?.map(item => item.ticketMedio) || [],
            color: '#4CAF50', // Cor para Ticket Médio
            area: true,
            visible: visibleLines.ticketMedio,
        },
        {
            label: 'Quantidade Pedidos',
            showMark: false,
            data: data?.pedidos?.map(item => item.quantidade) || [],
            color: '#1E90FF', // Cor para Quantidade Pedidos
            area: true,
            visible: visibleLines.quantidade,
        },
    ];

    const xLabels = data?.pedidos?.map(item => moment(item.date, 'YYYY-MM-DD').format('DD/MM')) || [];
    
    // Preparando os dados para o PieChart
    const pieData = Object.entries(data?.metodo_counts_total || {})
        // Ordenar os itens por valor (decrescente) e pegar os 5 primeiros
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5) // Pega os 5 maiores
        .map(([key, value]) => ({
            label: key,
            value: value,
        }));

    return (
        <Box display={'flex'} sx={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
            <Box sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {/* Controle de visibilidade das linhas */}
                <Box sx={{ marginBottom: '10px' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={visibleLines.faturamento}
                                onChange={() => toggleLineVisibility('faturamento')}
                            />
                        }
                        label="Faturamento R$"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={visibleLines.ticketMedio}
                                onChange={() => toggleLineVisibility('ticketMedio')}
                            />
                        }
                        label="Ticket Médio R$"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={visibleLines.quantidade}
                                onChange={() => toggleLineVisibility('quantidade')}
                            />
                        }
                        label="Quantidade Pedidos"
                    />
                </Box>
                
                {/* Gráfico de Linhas */}
                <LineChart
                    width={600}
                    height={280}
                    series={series.filter(line => line.visible)}  // Exibe apenas as linhas visíveis
                    yAxis={[{ disableTicks: true, disableLine: false }]}
                    grid={{horizontal: true, vertical: false}}
                    xAxis={[{ scaleType: 'point', data: xLabels, disableTicks: false, disableLine: true }]}
                    slotProps={{ legend: { hidden: true } }}
                    line
                />
            </Box>
            
            <Divider orientation="vertical" variant="middle" flexItem />
            
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} justifyItems={'center'} rowGap={'10px'}>
                <Typography sx={{ fontSize: '18px' }}>Pedidos p/Metodo de Pagamento</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <PieChart
                        series={[
                            {
                                data: pieData,
                                highlightScope: { fade: 'global', highlight: 'item' },
                                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            },
                        ]}
                        width={400}
                        height={200}
                        slotProps={{ legend: { hidden: false } }}
                    />
                </Box>
            </Box>
        </Box>
    );
}
