import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

export default function ListItemComponent({ color, text, value }){
    return (
        <ListItem>
            <ListItemAvatar>
                <CircleIcon
                    fontSize="inherit"
                    sx={{
                        color,
                        boxShadow: "6",
                        borderRadius: "100%",
                        backgroundColor: color,
                    }}
                />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Box display={"flex"} gap={1}>
                        <Typography width={"auto"} minWidth={'30px'}>{value}</Typography>
                        <Typography width={"auto"} minWidth={'150px'}>{text}</Typography>
                    </Box>
                }
            />
        </ListItem>
    );
};