import { Box, Grid, TextField, MenuItem, Button, Typography, IconButton, Collapse, Chip, Autocomplete } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useEffect, useState } from "react";
import { useGetFilters } from "../../../client/hooks/marketplace/marketplace.queries";

dayjs.locale("pt-br");

export default function TableFilter({ filters, setFilters, refetch }) {
    const [expanded, setExpanded] = useState(true);
    const [canFetch, setCanFetch] = useState(false);

    const { data } = useGetFilters(canFetch);

    useEffect(() => {
        setCanFetch(data ? false : true);
    }, [data]);

    const handleFilterChange = (event, field) => {
        setFilters({ ...filters, [field]: event.target.value });
    };

    const handleDateChange = (newValue, field) => {
        const formattedDate = newValue ? dayjs(newValue).format("DD/MM/YYYY") : '';
        setFilters({ ...filters, [field]: `${formattedDate}` });
    };

    const handleFilter = () => {
        setTimeout(() => refetch(), 100);
    };

    const handleClearFilters = () => {
        setFilters({
            category: "",
            brand: "",
            billingOrigin: "",
            marketplace: "",
            sku: [],
            status: "",
            dateFrom: "",
            dateTo: ""
        });
        setTimeout(() => refetch(), 100);
    };

    const getFilters = [
        {
            name: "category",
            label: "Categoria",
            type: "select",
            options: [...data?.categories || []],
        },
        {
            name: "brand",
            label: "Marca",
            type: "select",
            options: [...data?.brands || []],
        },
        {
            name: "billingOrigin",
            label: "Origem Faturamento",
            type: "select",
            options: ["Origem 1", "Origem 2", "Origem 3"],
        },
        {
            name: "marketplace",
            label: "Loja",
            type: "select",
            options: data?.marketplaces || [],
        },
        {
            name: "sku",
            label: "SKU",
            type: "text",
        },
        {
            name: "status",
            label: "Status",
            type: "select",
            options: [...data?.status || []],
        },
    ];

    const [filterFields, setFilterFields] = useState(getFilters);

    useEffect(() => setFilterFields(getFilters), [data]);

    const handleSkuChange = (event, newValue) => {
        setFilters({ ...filters, sku: newValue });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ padding: 2 }}>
                <Grid container spacing={2} justifyContent={'center'} rowGap={2} alignItems="center">
                    <Grid item xs={12} display="flex" alignItems="center">
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                            Filtros
                        </Typography>
                        <IconButton
                            onClick={() => setExpanded(!expanded)}
                            sx={{
                                marginLeft: 2,
                                margin: '0 0 5px 0',
                                color: "#1e88e5",
                                transition: "color 0.3s ease",
                                "&:hover": {
                                    color: "#1565c0",
                                }
                            }}
                        >
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Grid>

                    <Collapse in={expanded} sx={{ width: '100%', padding: '0 10px 10px 10px' }} timeout="auto" unmountOnExit>
                        <Grid container spacing={3} alignItems="center">
                            {filterFields.map((field, index) => (
                                <Grid item xs={12} sm={4} md={3} key={index}>
                                    {field.type === "select" && (
                                        <TextField
                                            fullWidth
                                            select
                                            label={field.label}
                                            value={filters[field.name]}
                                            onChange={(e) => handleFilterChange(e, field.name)}
                                            variant="outlined"
                                            sx={{
                                                backgroundColor: "#fafafa",
                                                borderRadius: 2,
                                                "& .MuiInputBase-root": {
                                                    height: "45px", // Ajusta a altura
                                                },
                                                '.MuiSelect-select': {
                                                    fontSize: '14px',
                                                    fontWeight: '600'
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: "#1e88e5",
                                                }
                                            }}
                                        >
                                            {field.options.map((option, idx) => (
                                                <MenuItem key={option.id || idx} value={option}>
                                                    {option.name || option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}

                                    {field.type === "text" && field.name !== "sku" && (
                                        <TextField
                                            fullWidth
                                            label={field.label}
                                            value={filters[field.name]}
                                            onChange={(e) => handleFilterChange(e, field.name)}
                                            variant="outlined"
                                            sx={{
                                                backgroundColor: "#fafafa",
                                                borderRadius: 2,
                                                "& .MuiInputBase-root": {
                                                    height: "45px", // Ajusta a altura
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: "#1e88e5",
                                                }
                                            }}
                                        />
                                    )}

                                    {/* Campo SKU com múltiplos valores */}
                                    {field.type === "text" && field.name === "sku" && (
                                        <Box sx={{ position: "relative", width: "100%" }}>
                                            <Autocomplete
                                                multiple
                                                freeSolo
                                                id="tags-filled"
                                                value={filters.sku || []}
                                                onChange={handleSkuChange}
                                                options={[]}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => {
                                                        const { key, ...tagProps } = getTagProps({ index });
                                                        return (
                                                            <Chip 
                                                            variant="outlined" 
                                                            sx={{
                                                                height: '22px', 
                                                                fontSize: '12px', 
                                                                padding: '0 6px', 
                                                                '.MuiChip-label': { marginTop: '2px' }, 
                                                                '.MuiSvgIcon-root': { fontSize: '12px'},
                                                                marginRight: '6px'
                                                            }}  
                                                            label={option} 
                                                            key={key} 
                                                            {...tagProps} />
                                                        );
                                                    })
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="SKU"
                                                        placeholder="Digite ou selecione um SKU"
                                                        sx={{
                                                            backgroundColor: "#fafafa",
                                                            borderRadius: 2,
                                                            "& .MuiInputBase-root": {
                                                                height: "45px", // Ajusta a altura
                                                                alignContent: 'center',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    )}
                                </Grid>
                            ))}

                            {/* Campos de data "De" e "Até" */}
                            <Grid item xs={12} sm={4} md={3}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={6}>
                                        <DatePicker
                                            label="De"
                                            format="DD/MM/YYYY"
                                            value={filters["dateFrom"] ? dayjs(filters["dateFrom"], "DD/MM/YYYY") : null}
                                            onChange={(newValue) => handleDateChange(newValue, "dateFrom")}
                                            sx={{
                                                '.MuiInputBase-input': { 
                                                    padding: '11px 14px', 
                                                    textAlign: 'center' 
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    fullWidth
                                                    inputFormat="DD/MM/YYYY"
                                                    sx={{
                                                        backgroundColor: "#fafafa",
                                                        borderRadius: 2,
                                                        "& .MuiInputBase-root": {
                                                            height: "45px", // Ajusta a altura
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: "#1e88e5",
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <DatePicker
                                            label="Até"
                                            format="DD/MM/YYYY"
                                            value={filters["dateTo"] ? dayjs(filters["dateTo"], "DD/MM/YYYY") : null}
                                            onChange={(newValue) => handleDateChange(newValue, "dateTo")}
                                            sx={{
                                                '.MuiInputBase-input': { padding: '11px 14px' },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    fullWidth
                                                    inputFormat="DD/MM/YYYY"
                                                    sx={{
                                                        backgroundColor: "#fafafa",
                                                        borderRadius: 2,
                                                        "& .MuiInputBase-root": {
                                                            height: "25px", // Ajusta a altura
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: "#1e88e5",
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Collapse>

                    {/* Botões */}
                    <Box sx={{ display: "flex", justifyContent: "center", gap: "20px", paddingTop: '20px' }}>
                        <Button
                            variant="outlined"
                            onClick={handleClearFilters}
                            sx={{
                                height: '45px',
                                width: '120px',
                                borderRadius: '8px',
                                backgroundColor: '#f9f9f9',
                                lineHeight: '16px',
                                '&:hover': {
                                    backgroundColor: '#e4e4e4',
                                },
                                fontWeight: 600,
                                "&:active": {
                                    transform: "scale(0.98)",
                                    transition: "transform 0.1s ease-in-out",
                                },
                                transition: "background-color 0.3s ease, transform 0.2s ease",
                            }}
                        >
                            Limpar Filtros
                        </Button>
                        <Button
                            variant="contained"
                            color="#FFF"
                            onClick={handleFilter}
                            sx={{
                                color: "#FFF",
                                height: '45px',
                                width: '120px',
                                borderRadius: '8px',
                                fontSize: '14px',
                                fontWeight: 900,
                                backgroundColor: '#ff8b00', // Verde suave
                                '&:hover': {
                                    backgroundColor: '#b3630296', // Verde mais escuro
                                },
                                "&:active": {
                                    transform: "scale(0.98)",
                                    transition: "transform 0.1s ease-in-out",
                                },
                                transition: "background-color 0.3s ease, transform 0.2s ease",
                            }}
                        >
                            Filtrar
                        </Button>
                    </Box>
                </Grid>
            </Box>
        </LocalizationProvider>
    );
}
