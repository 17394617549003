import axios from "axios"

    // DEV_URI: 'https://api-n1.walasmartwork.com.br/v1',
    // STG_URI: 'https://api-n1.walasmartwork.com.br/v1',
    // PRD_URI: 'https://api-n1.walasmartwork.com.br/v1'


export const api = axios.create({
    baseURL: 'https://api-n1.walasmartwork.com.br/v1',
    headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem('token')}`,
    },
})

api.interceptors.response.use(
    (response) => response, 
    (error) => {
      if (error.response?.status === 401 && window.location.pathname !== "/login") {
        
        localStorage.clear(); 
        document.cookie = ""; 
        window.location.replace("/login"); 
      }
      return Promise.reject(error); 
    }
  );