import { Box, Typography, Button, Drawer } from "@mui/material";
import { useState } from "react";

export default function HeaderLandingComponent({ theme }) {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const styles = {
    color: "#FF7323",
    fontFamily: "sans-serif",
    fontSize: "18px",
    fontWeight: 600,
    cursor: "pointer",
    padding: "2px 4px",
    borderRadius: "4px",
    ":hover": {
      color: "#FFF",
      backgroundColor: "#ff8266",
    },
  };

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      toggleDrawer(false);
    }
  };

  function goTo(url) {
    window.open(url, "_blank");
  }

  return (
    <>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "200px",
            color: "#ff8266",
          },
        }}
      >
        <Box
          sx={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography sx={styles} onClick={() => handleScroll("about")}>
            Sobre
          </Typography>
          <Typography sx={styles} onClick={() => handleScroll("benefits")}>
            Benefícios
          </Typography>
          <Typography sx={styles} onClick={() => handleScroll("contact")}>
            Contato
          </Typography>
        </Box>
      </Drawer>

      <Box
        sx={{
          width: "100%",
          backgroundColor: "#fff",
          boxShadow: 6,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: { xs: "10px 0", sm: "5px 0" },
          position: "relative",
          [theme.breakpoints.down("sm")]: {
            flexDirection: "row",
          },
        }}
      >
        <Box
          component="img"
          src={require("../../../assets/wala.png")}
          alt="Logo"
          sx={{
            height: { xs: "50px", sm: "70px", md: "90px" },
            width: "auto",
            marginBottom: { xs: "10px", sm: "0" },
            marginLeft: "30px",
            marginRight: "auto",
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          }}
        />

        <Box
          display={"flex"}
          gap={6}
          alignItems={"center"}
          sx={{
            width: "100%",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          }}
        >
          <Typography sx={styles} onClick={() => handleScroll("about")}>
            Sobre
          </Typography>
          <Typography sx={styles} onClick={() => handleScroll("benefits")}>
            Benefícios
          </Typography>
          <Typography sx={styles} onClick={() => handleScroll("contact")}>
            Contato
          </Typography>
        </Box>

        <Box
          sx={{
            display: { xs: "block", sm: "none" },
            cursor: "pointer",
            marginLeft: "15px",
          }}
          onClick={() => toggleDrawer(true)}
        >
          <Typography sx={{ ...styles, fontSize: "20px" }}>☰</Typography>{" "}
          {/* Hamburger Icon */}
        </Box>

        <Box
          display={"flex"}
          gap={4}
          alignItems={"center"}
          sx={{
            marginTop: { sm: "10px" },
            marginRight: { xs: "10px" },
          }}
        >
          <Button
            onClick={() => goTo("login")}
            sx={{
              color: "#ff8266",
              padding: { sm: "4px 16px" },
              borderRadius: "25px",
              fontSize: { sm: "22px", xs: "16px" },
              fontWeight: 600,
              textTransform: "none",
            }}
          >
            Login
          </Button>
        </Box>
      </Box>
    </>
  );
}
