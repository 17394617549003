import { useQuery } from "@tanstack/react-query";
import { api } from "../../config";

 function useGetOrders(params, enabl) {
    return useQuery({
        queryFn: async () => {
            const res = await api.get(`/orders`, {params});
            return res.data;
        },
        queryKey: ['paged-orders'],
        enabled: enabl,
        retry: false,
        refetchOnWindowFocus: false
    })
}

function useGetOrdersMetrics(enabl) {
    return useQuery({
        queryFn: async () => {
            const res = await api.get(`/orders/metrics`);
            return res.data;
        },
        queryKey: ['metrics-orders'],
        enabled: enabl,
        retry: false,
        refetchOnWindowFocus: false
    })
}


export {
    useGetOrders,
    useGetOrdersMetrics
}