import { Box, Card, CardContent, Typography } from "@mui/material";

export default function BenefitsComponent({ theme }) {
  const cards = [
    {
      title: "Lucratividade por Pedido",
      description:
        "Monitoramento em tempo real da lucratividade de cada pedido, 24 horas por dia, 7 dias por semana.",
    },
    {
      title: "Analise de Estoque",
      description:
        "Monitoramento do estoque para tomada de decisão nas compras ou na formação de preço.",
    },
    {
      title: "Gerenciamento de Preços",
      description: "Precificação automática através de regras de negócio.",
    },
    {
      title: "Promoções",
      description: "Análise e aceite de promoções de forma automática.",
    },
  ];

  return (
    <Box
      padding={"2% 2%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      gap={8}
      sx={{
        background: "linear-gradient(to bottom, #FFF, #f7f7f7)",
        [theme.breakpoints.down("sm")]: {
          padding: "5%",
          gap: 5,
        },
      }}
    >
      <Box textAlign="center" marginTop="5%" marginBottom="5%">
        <Typography
          fontFamily={"Bree Serif"}
          fontSize={"80px"}
          fontWeight={800}
          color="#FF7323"
          sx={{
            fontSize: { xs: "50px", sm: "80px", md: "90px" },
          }}
        >
          Benefícios do Wala Smart Work
        </Typography>
      </Box>

      <Box
        textAlign="center"
        margin="auto"
        width="75%"
        sx={{
          [theme.breakpoints.down("sm")]: {
            width: "auto",
          },
        }}
      >
        <Typography
          fontSize={{ xs: "18px", sm: "25px", md: "35px" }}
          lineHeight={1.6}
        >
          Vamos monitorar{" "}
          <span style={{ color: "#FF7323", fontWeight: "bold" }}>
            24 horas por dia
          </span>{" "}
          o seu negócio, para que{" "}
          <span style={{ fontWeight: "bold" }}>
            você foque no que realmente importa.
          </span>
        </Typography>
        <Typography
          fontSize={{ xs: "18px", sm: "25px", md: "35px" }}
          lineHeight={1.6}
        >
          Algoritmos, integrações, notificações, IA e muito mais.
        </Typography>
        <Typography
          fontSize={{ xs: "18px", sm: "25px", md: "35px" }}
          lineHeight={1.6}
        >
          Wala Smart Work é tecnologia aplicada ao que realmente importa.
        </Typography>
      </Box>

      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        gap={8}
        margin="auto"
        marginTop="5%"
        marginBottom="5%"
        sx={{
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            gap: 5,
          },
        }}
      >
        {cards.map((card, index) => (
          <Card
            key={index}
            sx={{
              maxWidth: "calc(30% - 16px)",
              flex: "1 1 calc(45% - 16px)",
              borderRadius: "20px",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0 8px 30px rgba(0, 0, 0, 0.2)",
              },
              [theme.breakpoints.down("md")]: {
                maxWidth: "100%",
                flex: "1 1 100%",
              },
            }}
          >
            <CardContent sx={{ padding: 0 }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  height: { xs: "100px", sm: "120px", md: "160px" },
                  backgroundImage:
                    "linear-gradient(90deg, #FF7323, #FFA726, #FFD180)",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                }}
              >
                <Typography
                  fontSize={{ xs: "20px", sm: "25px", md: "30px" }}
                  fontWeight="bold"
                  color="#FFF"
                  textAlign="center"
                >
                  {card.title}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                padding="20px"
              >
                <Typography
                  fontSize={{ xs: "14px", sm: "18px", md: "20px" }}
                  color="#555"
                  textAlign="center"
                  lineHeight={1.8}
                >
                  {card.description}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
}
