import ReactInputMask from "react-input-mask";
import React from "react";
import { TextField } from "@mui/material";

const MaskedInput = React.forwardRef((props, ref) => (
  <ReactInputMask {...props}>
    {(inputProps) => <TextField {...inputProps} inputRef={ref} />}
  </ReactInputMask>
));

export default MaskedInput;
