import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';

export const exportStyledTableToXLSX = (data, selectedRows, fileName = 'tabela_pedidos.xlsx') => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Orders');

    worksheet.columns = [
        { header: 'Pedido', key: 'partnerId', width: 15 },
        { header: 'Itens', key: 'items', width: 10 },
        { header: 'Marketplace', key: 'marketPlace', width: 20 },
        { header: 'Status', key: 'status', width: 20 },
        { header: 'Marketplace ID', key: 'marketPlaceId', width: 20 },
        { header: 'Total', key: 'total', width: 15 },
        { header: 'Desconto', key: 'discount', width: 15 },
        { header: 'Frete Pago', key: 'freight', width: 15 },
        { header: 'Total Produto', key: 'totalProduto', width: 15 },
        { header: 'Frete Gratis', key: 'sellerFreight', width: 15 },
        { header: 'Total CVM', key: 'totalCvm', width: 15 },
        { header: 'Comissão', key: 'comission', width: 15 },
        { header: 'Imposto', key: 'impostoTotal', width: 15 },
        { header: 'VPC', key: 'vpc', width: 15 },
        { header: 'RMB', key: 'rmb', width: 15 },
        { header: 'TX FIXA', key: 'txFixa', width: 15 },
        { header: '= Margem', key: 'equalMar', width: 15 },
        { header: '% Margem', key: 'percentMar', width: 15 },
        { header: 'Estado', key: 'state', width: 15 },
        { header: 'Data de Criação', key: 'createdAt', width: 20 },
    ];

    worksheet.getRow(1).eachCell(cell => {
        cell.font = { bold: true, color: { argb: 'FFFFFF' } };
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '34495E' },
        };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
    });

    worksheet.getRow(1).height = 40;

    selectedRows.forEach((rowIndex, idx) => {
        const row = data[rowIndex];
        worksheet.addRow({
            partnerId: row.partnerId,
            items: row.items.length,
            marketPlace: row.marketPlace,
            status: row.status,
            marketPlaceId: row.marketPlaceId,
            total: `R$ ${row.total.toFixed(2).replace('.', ',')}`,
            discount: `R$ ${row.discount.toFixed(2).replace('.', ',')}`,
            freight: `R$ ${row.freight.toFixed(2).replace('.', ',')}`,
            totalProduto: `R$ ${row.totalProduto?.toFixed(2).replace('.', ',')}`,
            sellerFreight: `R$ ${row.sellerFreight.toFixed(2).replace('.', ',')}`,
            totalCvm: `R$ ${row.totalCvm?.toFixed(2).replace('.', ',')}`,
            comission: `R$ ${row.comission?.toFixed(2).replace('.', ',') || '0,00'}`,
            impostoTotal: `R$ ${row.impostoTotal?.toFixed(2).replace('.', ',')}`,
            vpc: `R$ 0,00`,
            rmb: `R$ 0,00`,
            txFixa: `R$ 0,00`,
            percentMar: `${row.percentMar?.toFixed(2).replace('.', ',')}%`,
            equalMar: `R$ ${row.equalMar?.toFixed(2).replace('.', ',')}`,
            state: row.shipping?.state,
            createdAt: moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        });

        const currentRow = worksheet.getRow(idx + 2);
        const isEven = idx % 2 === 0;
        currentRow.eachCell((cell, colNumber) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: isEven ? 'FFFFFF' : 'F2F2F2' },
            };

            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

            if (colNumber === 6 || colNumber === 7 || colNumber === 8 || colNumber === 9 || colNumber === 10 || colNumber === 11 || colNumber === 12) {
                cell.numFmt = '"R$ "#,##0.00';
            } else if (colNumber === 17 || colNumber === 18) {
                cell.numFmt = '0.00%';
            }

            if (colNumber === 11 || colNumber === 12 || colNumber === 13 ) {
                cell.font = { color: { argb: '#fc3434' } };
            }

            if (colNumber === 17 || colNumber === 18) { 
                const percentMarValue = row.percentMar;
                if (percentMarValue < 0) {
                    cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'efb5b9' } };
                    cell.font = { color: { argb: '#fc3434' } };
                } else if (percentMarValue < 5) {
                    cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'f2cea7' } };
                    cell.font = { color: { argb: 'black' } };
                } else if (percentMarValue < 10) {
                    cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'caf5c1' } };
                    cell.font = { color: { argb: '#2e31ff' } };
                } else {
                    cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DEEEFF' } };
                    cell.font = { color: { argb: '#2e31ff' } };

                }
            }
        });
    });

    workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(new Blob([buffer]), fileName);
    });
};
