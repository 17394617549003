import React from "react";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const Top5SkusTable = () => {
  const topSkus = {
    total: 1096,
    skus: [
      {
        sku: "TRA23799071",
        text: "Faqueiro Tramontina Aço Inox Buzios 24 Peç...",
        quantity: 306,
      },
      {
        sku: "MOR002002",
        text: "Cadeira de Praia Alta em Aço 110 kg cores Va...",
        quantity: 272,
      },
      {
        sku: "MOR002290",
        text: "Cadeira de Praia Piscina Reclinavel 8 Posiçõe...",
        quantity: 213,
      },
      {
        sku: "MOR006105",
        text: "Varal de Chão com Abas Retratil Slim Mor",
        quantity: 180,
      },
      {
        sku: "TRA20591420",
        text: "Panela de Pressão 4,5 Litros Vancouver Tra...",
        quantity: 125,
      },
    ],
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  return (
    <Box padding={"30px"}>
      {/* Header */}
      <Box display="flex" alignItems="center" marginBottom="20px">
        <Box display="flex" flexDirection="column" rowGap={1}>
          <Box display="flex" alignItems="center" columnGap={1}>
            <Typography fontWeight={700} fontSize="22px">
              Top 5 SKU's mais vendidos
            </Typography>
            <InfoIcon fontSize="small" sx={{ color: "gray" }} />
          </Box>
          <Typography fontWeight={900}>{topSkus.total} produtos</Typography>
        </Box>
        <IconButton sx={{ marginLeft: "auto" }}>
          <MoreVertIcon />
        </IconButton>
      </Box>

      {/* Table */}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography fontWeight="bold" marginLeft="6%">
                SKU
              </Typography>
            </TableCell>
            <TableCell
              align="right"
              sx={{ width: "30%" }} // Ajuste da largura da coluna de quantidade
            >
              <Typography fontWeight="bold" marginRight="6%">
                Quantidade
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {topSkus.skus.map((value, index) => (
            <TableRow key={index}>
              <TableCell>
                <Box display="flex" alignItems="center" columnGap={2}>
                  <Typography
                    fontSize="14px"
                    sx={{
                      backgroundColor: "#8080803b",
                      borderRadius: "50%",
                      padding: "1px 7px",
                    }}
                  >
                    {index + 1}
                  </Typography>
                  <Tooltip title={`${value.sku} - ${value.text}`}>
                    <Typography fontSize="14px">
                      {truncateText(`${value.sku} - ${value.text}`, 60)}
                    </Typography>
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell align="right" sx={{ width: "25%" }}>
                <Box display="flex" alignItems="center" columnGap={2}>
                  <Typography
                    sx={{ fontSize: "12px", color: "gray", width: "40%" }}
                  >
                    {value.quantity} un
                  </Typography>
                  <LinearProgress
                    sx={{ width: "60%" }}
                    variant="determinate"
                    value={(value.quantity * 100) / topSkus.total}
                  />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default Top5SkusTable;
