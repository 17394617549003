import { Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider, TableFooter } from '@mui/material';
import OrdersMargemCard from './OrdersMargem.component';
import MargemLineChart from './MargemLineChart.component';
import { useGetOrdersMetrics } from '../../../client/hooks/orders/order.queries';
import { useEffect, useState } from 'react';

export default function OrderMargemSection() {
    const [canFetch, setCanFetch] = useState(false);
    const { data, isLoading, isRefetching, refetch } = useGetOrdersMetrics(canFetch);

    useEffect(() => {
        setCanFetch(data ? false : true);
    }, [data]);

    // Ordenar os dados de marketplace com base no total (em ordem decrescente)
    const sortedMarketplaces = data?.marketplaces_margem?.sort((a, b) => b.total - a.total) || [];

    // Calcular as somas de VENDA(+), =MAR e MAR%
    const totalVenda = sortedMarketplaces.reduce((sum, row) => sum + (row.total || 0), 0);
    const totalEqualMar = sortedMarketplaces.reduce((sum, row) => sum + (row.equalMar || 0), 0);
    const totalMarPercentage = (totalEqualMar * 100) / totalVenda || 0;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', width: 'auto', gap: 6, margin: '20px 0 20px 0', padding: '10px', justifyContent: 'center' }}>
            {/* OrdersMargemCard */}
            <Box sx={{ width: 'auto' }}><OrdersMargemCard data={data} /></Box>
            {/* Linha de Cards e Gráficos */}
            <Box sx={{ display: 'flex', flexDirection: 'column', width: 'auto', rowGap: '15px' }}>
                {/* Primeira linha: Cards */}
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '100%', justifyContent: 'end' }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '90%',
                        backgroundColor: '#deeeff',
                        padding: '10px', borderRadius: '6px', boxShadow: 2
                    }}>
                        <Typography sx={{ fontWeight: '700', textAlign: 'center', fontSize: '16px' }}>Margem Total</Typography>
                        <Typography sx={{ backgroundColor: '#b2ccfd2e', padding: '5px', textAlign: 'center' }}>{new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(data?.totalMargem?.toFixed(2) || 0)}</Typography>
                    </Box>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '90%',
                        backgroundColor: '#fca28c',
                        padding: '10px', borderRadius: '6px', boxShadow: 2
                    }}>
                        <Typography sx={{ fontWeight: '700', textAlign: 'center', fontSize: '16px' }}>Margem Negativa</Typography>
                        <Typography sx={{ backgroundColor: '#be40033b', padding: '5px', textAlign: 'center' }}>{new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(data?.margemNegativa?.toFixed(2) || 0)}</Typography>
                    </Box>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '90%',
                        backgroundColor: '#caf5c1',
                        padding: '10px', borderRadius: '6px', boxShadow: 2
                    }}>
                        <Typography sx={{ fontWeight: '700', textAlign: 'center', fontSize: '16px' }}>Margem Positiva</Typography>
                        <Typography sx={{ backgroundColor: '#84be0340', padding: '5px', textAlign: 'center' }}>{new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(data?.margemPositiva?.toFixed(2) || 0)}</Typography>
                    </Box>
                </Box>

                {/* Segunda linha: Gráficos */}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', // Centraliza verticalmente
                    justifyContent: 'center',
                    height: '100%', // Certifique-se de que o pai tenha altura definida
                    width: '100%'
                }}>
                    <MargemLineChart data={data} />
                </Box>
            </Box>

            {/* Tabela */}
            <Box display={'flex'} sx={{ width: 'auto', height: '100%' }}>
                <TableContainer
                    sx={{
                        boxShadow: "none",
                        borderRadius: "8px",
                        backgroundColor: "#f9f9f9", // Fundo suave
                        maxWidth: "100%",
                        height: '100%', // Limite de altura
                        overflow: "hidden", // Impede rolagem da tabela inteira
                    }}
                    component={Paper}
                >
                    <Table size="small" aria-label="tabela" sx={{ display: 'flex', flexDirection: 'column', height: '100%',}}>
                        <TableHead
                            sx={{
                                position: 'sticky',
                                top: 0,
                                zIndex: 1,
                                backgroundColor: "#34495e", // Cabeçalho diferenciado
                            }}
                        >
                            <TableRow sx={{ display: 'flex', width: '100%', justifyContent: 'space-around'}}>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: "#fff",
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        padding: "10px",
                                    }}
                                >
                                    MARKETPLACE
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{
                                        color: "#fff",
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        padding: "10px 0 10px 60px",
                                    }}
                                >
                                    VENDA(+)
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{
                                        color: "#fff",
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        padding: "10px 0 10px 30px",
                                    }}
                                >
                                    =MAR
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{
                                        color: "#fff",
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        padding: "10px 0 10px 30px",
                                    }}
                                >
                                    MAR%
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody
                            sx={{
                                overflowY: 'auto', // Tabela rolável
                                height: '300px', // Altura para rolar as linhas
                                display: 'block',
                            }}
                        >
                            {sortedMarketplaces.map((row, index) => {
                                const total = new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(row?.total?.toFixed(2) || 0); // Converte para número
                                const equalMar = new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(row?.equalMar?.toFixed(2) || 0); // Converte para número
                                const marPercentage = Number(((row?.equalMar * 100) / row?.total).toFixed(2) || 0); // Cálculo do MAR%

                                return (
                                    <TableRow
                                        key={row.name}
                                        sx={{
                                            backgroundColor: index % 2 === 0 ? "#ecf0f1" : "#fff", // Linhas alternadas
                                            "&:hover": {
                                                backgroundColor: "#dfe6e9", // Efeito hover
                                            },
                                            transition: "background-color 0.2s",
                                        }}
                                    >
                                        <TableCell
                                            align="left"
                                            sx={{
                                                fontSize: "13px",
                                                color: "#2c3e50",
                                                padding: "8px",
                                            }}
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{
                                                fontSize: "13px",
                                                color: "#1a73c3",
                                                padding: "8px",
                                            }}
                                        >
                                            {total}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{
                                                fontSize: "13px",
                                                color: "#2a8323",
                                                padding: "8px",
                                            }}
                                        >
                                            {equalMar}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{
                                                fontSize: "13px",
                                                color: "#2c3e50",
                                                padding: "8px",
                                                backgroundColor: marPercentage > 7 ? '#deefff' : '#f0e199'
                                            }}
                                        >
                                            {marPercentage}%
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter
                            sx={{
                                position: 'sticky',
                                bottom: 0,
                                zIndex: 1,
                                backgroundColor: "#34495e",
                                width: '100%'
                            }}
                        >
                            <TableRow sx={{ display: 'flex', width: '100%', justifyContent: 'space-around'}}>
                                <TableCell
                                    align="left"
                                    sx={{
                                        fontSize: "13px",
                                        color: "#fff",
                                        padding: "8px",
                                        fontWeight: "bold",
                                        padding: "10px 0 10px 0",
                                        margin: "0 0 0 20px",
                                    }}
                                >
                                    Totais
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{
                                        fontSize: "13px",
                                        color: "#fff",
                                        padding: "8px",
                                        fontWeight: "bold",
                                        margin: "0 0 0 140px",
                                    }}
                                >
                                    {new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(totalVenda.toFixed(2))}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{
                                        fontSize: "13px",
                                        color: "#fff",
                                        padding: "8px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(totalEqualMar.toFixed(2))}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{
                                        fontSize: "13px",
                                        color: "#fff",
                                        padding: "8px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {new Intl.NumberFormat('pt-Br', { style: 'decimal' }).format(totalMarPercentage.toFixed(2))}%
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
}
