import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Alert, IconButton, Snackbar, Tooltip, Fab } from "@mui/material";
// import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AddIcon from "@mui/icons-material/Add";
import { useGetAffiliates } from "../../client/hooks/users/user.queries";
import UserDialogComponent from "../components/UserComponents/user.dialog.component";

export default function UsersPage() {
  const [performUsers, setPerformUsers] = useState(true);
  const { data, isLoading, isError, error, refetch } =
    useGetAffiliates(performUsers);

  const [alert, setAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [rows, setRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    avatar: "",
  });

  // function openPermissionsDialog() {
  //   console.log("openPermissionsDialog");
  // }

  function openUserDialog(user = null) {
    if (user) {
      setUser(user);
    }
    setOpenDialog(true);
  }

  useEffect(() => {
    if (data) {
      setRows(data);
    }
    if (isError) {
      const errorMessage =
        error?.response?.data?.msg ||
        error?.message ||
        "Ocorreu um erro inesperado.";

      setErrorMsg(errorMessage);
      setAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data]);

  function loadMsg() {
    setOpenDialog(false);
    refetch();
    setAlert(true);
    setSuccessMsg("Usuário criado com sucesso");
    setPerformUsers(true);
  }

  function closeDialog(reload) {
    if (reload) {
      refetch();
    }
    setOpenDialog(false);
    setTimeout(() => setUser({}), 500);
  }

  return (
    <>
      <Snackbar
        autoHideDuration={5000}
        open={alert}
        onClose={() => setAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setAlert(false)}
          severity={isError ? "error" : "success"}
        >
          {errorMsg === "" ? successMsg : errorMsg}
        </Alert>
      </Snackbar>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Telefone</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.phone}</TableCell>

                <TableCell align="center" sx={{ width: 100 }}>
                  {/* <Tooltip title="Gerenciar permissoes">
                    <IconButton onClick={openPermissionsDialog}>
                      <KeyOutlinedIcon />
                    </IconButton>
                  </Tooltip> */}
                  <Tooltip title="Editar usuário">
                    <IconButton  onClick={() => openUserDialog(row)}>
                      <CreateOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab
        color="primary"
        aria-label="add"
        onClick={openUserDialog}
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
      >
        <AddIcon />
      </Fab>
      <UserDialogComponent
        open={openDialog}
        onCloseDialog={(reload) => closeDialog(reload)}
        isLoading={true}
        user={user}
        showAlert={loadMsg}
      />
    </>
  );
}
