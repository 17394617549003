import { Avatar, Box, Divider, Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { StyledBadge } from "./components/badge.component";

export default function UserAppBarComponent({
  open,
  onAction,
  user,
  isLoading,
}) {
  function setValidChar(name) {
    if (name.length > 13) {
      name = name.substring(0, 13) + "...";
      return name;
    }
    return name;
  }
  return (
    <>
      <Box
        onClickCapture={() => onAction(true)}
        sx={
          open
            ? {
                display: "flex",
                border: "1px solid #80808033",
                padding: "4px 8px 4px 8px",
                flexDirection: "row",
                alignItems: "center",
                columnGap: 1,
                background: "#FFEFE9",
                borderRadius: "6px",
                color: "#423a3a",
                width: "90%",
                cursor: "pointer",
              }
            : {
                cursor: "pointer",
                borderRadius: "50%",
              }
        }
      >
        {isLoading ? (
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
        ) : (
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
          >
            <Avatar
              alt={`${user.name}`}
              src={user.avatar ? user.avatar : "google.cin"}
              sx={{
                boxShadow: 2,
                ":hover": open
                  ? null
                  : { opacity: "0.9", transitionDelay: "50ms" },
              }}
            />
          </StyledBadge>
        )}
        {open && <Divider orientation="vertical" variant="middle" flexItem />}
        {open &&
          (isLoading ? (
            <Skeleton width={100} height={20}>
              <Typography
                marginLeft={"auto"}
                marginRight={"5px"}
                marginTop={"5px"}
                fontSize={"15px"}
                fontWeight={400}
                color="#5b5a5a"
              ></Typography>
            </Skeleton>
          ) : (
            <Typography
              marginRight={"5px"}
              marginTop={"5px"}
              fontSize={"15px"}
              fontWeight={400}
              color="#5b5a5a"
            >
              {setValidChar(user.name)}
            </Typography>
          ))}
      </Box>
    </>
  );
}
