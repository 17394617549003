import { useState, useEffect } from "react";
import { Container, createTheme, ThemeProvider, Fab, Box } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import HeaderLandingComponent from "./components/header.component";
import MainFrameComponent from "./components/main.frame.component";
import BenefitsComponent from "./components/benefits.frame.component";
import ContactPage from "./components/contact.frame.component";
import AboutPage from "./components/about.frame.component";

export default function LandingPage() {
  const theme = createTheme({
    cssVariables: true,
    palette: {
      primary: {
        main: "#FF7323",
      },
      background: {
        default: "#FFF",
      },
    },
    breakpoints: {
      values: {
        xs: 0, // Dispositivos pequenos
        sm: 600, // Tablets
        md: 960, // Laptops pequenos
        lg: 1280,
        xl: 1920,
      },
    },
  });

  const [showScrollButton, setShowScrollButton] = useState(false);

  // Função para monitorar a rolagem
  const handleScroll = () => {
    setShowScrollButton(window.scrollY > 300);
  };

  // Adiciona evento de rolagem
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Função para rolar até o topo
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        fixed={true}
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#eae8e4",
          minWidth: "100%",
          minHeight: "100vh",
          margin: "0",
          padding: "0",
          [theme.breakpoints.down("sm")]: {},
        }}
        maxWidth={false}
        disableGutters={true}
      >
        <HeaderLandingComponent theme={theme} />
        <div id="mainFrame">
          <MainFrameComponent theme={theme} />
        </div>
        <div id="benefits">
          <BenefitsComponent theme={theme} />
        </div>
        <div id="about">
          <AboutPage theme={theme} />
        </div>
        <div id="contact">
          <ContactPage theme={theme} />
        </div>

        <Box
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            zIndex: 1000,
          }}
        >
          {showScrollButton && (
            <Fab
              color="primary"
              aria-label="voltar ao topo"
              onClick={scrollToTop}
            >
              <ArrowUpwardIcon />
            </Fab>
          )}

          <Fab
            color="secondary"
            aria-label="WhatsApp"
            href="https://wa.me/5551997971635?text=Quero%20saber%20sobre%20o%20wala%20smart%20work"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              backgroundColor: "#25D366",
              "&:hover": {
                backgroundColor: "#1EBF57",
              },
            }}
          >
            <WhatsAppIcon sx={{ color: "#fff" }} />
          </Fab>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
