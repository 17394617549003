import { Box, Typography } from "@mui/material";

export default function AboutPage({ theme }) {
  return (
    <Box
      sx={{
        padding: "5% 10%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 8,
      }}
    >
      <Typography
        variant="h2"
        fontWeight={800}
        textAlign="center"
        color="#FF7323"
        sx={{
          fontSize: { xs: "40px", sm: "60px", md: "70px" },
          marginBottom: 2,
        }}
      >
        Sobre Nós
      </Typography>

      <Typography
        variant="body1"
        textAlign="center"
        color="#555"
        sx={{
          fontSize: { xs: "16px", sm: "18px", md: "22px" },
          marginBottom: 4,
        }}
      >
        O <span style={{ fontWeight: "bold" }}>Wala Smart Work</span> é uma
        plataforma SaaS inovadora desenvolvida para revolucionar o e-commerce.
        Nossa solução centraliza informações essenciais, automatiza tarefas
        estratégicas e oferece insights poderosos para impulsionar os resultados
        do seu negócio.
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 5,
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              flex: 1,
              textAlign: "center",
              background: "#fff",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
            }}
          >
            <Typography variant="h6" color="#FF7323" fontWeight={600}>
              Centralização de Informações
            </Typography>
            <Typography variant="body2" color="#555" sx={{ marginTop: 2 }}>
              Acesse todos os dados importantes do seu negócio em um único
              lugar, simplificando sua gestão e tomada de decisões.
            </Typography>
          </Box>

          <Box
            sx={{
              flex: 1,
              textAlign: "center",
              background: "#fff",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
            }}
          >
            <Typography variant="h6" color="#FF7323" fontWeight={600}>
              Automação de Tarefas
            </Typography>
            <Typography variant="body2" color="#555" sx={{ marginTop: 2 }}>
              Reduza o trabalho manual automatizando processos repetitivos,
              permitindo que você foque no crescimento do seu negócio.
            </Typography>
          </Box>

          <Box
            sx={{
              flex: 1,
              textAlign: "center",
              background: "#fff",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
            }}
          >
            <Typography variant="h6" color="#FF7323" fontWeight={600}>
              Redução de Perdas
            </Typography>
            <Typography variant="body2" color="#555" sx={{ marginTop: 2 }}>
              Identifique gargalos no fluxo de trabalho e minimize perdas com
              relatórios inteligentes e ferramentas de análise.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Typography
        variant="body1"
        textAlign="center"
        color="#555"
        sx={{
          fontSize: { xs: "16px", sm: "18px", md: "20px" },
        }}
      >
        Com o <span style={{ fontWeight: "bold" }}>Wala Smart Work</span>, você
        transforma desafios em oportunidades e leva seu e-commerce ao próximo
        nível. Estamos aqui para ajudar você a crescer de forma eficiente e
        sustentável.
      </Typography>
    </Box>
  );
}
