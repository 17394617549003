import { Box, Button, Typography } from "@mui/material";

export default function MainFrameComponent({ theme }) {
  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <Box
      padding={"2% 2%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      gap={10}
      sx={{
        [theme.breakpoints.down("sm")]: {
          padding: "5%",
          gap: 5,
        },
      }}
    >
      <Box
        display={"flex"}
        width={"auto"}
        flexDirection={"column"}
        margin={"auto"}
        marginTop={"5%"}
        marginBottom={"5%"}
        sx={{
          [theme.breakpoints.down("sm")]: {
            marginTop: "5%",
            marginBottom: "5%",
          },
        }}
      >
        <Typography
          fontFamily={"Bree Serif"}
          fontSize={"130px"}
          fontWeight={800}
          color="#FF7323"
          textAlign={"center"}
          sx={{
            fontSize: { xs: "50px", sm: "90px", md: "130px" },
          }}
        >
          Wala SmartWork
        </Typography>
        <Typography
          variant="h4"
          textAlign={"end"}
          marginRight={"25px"}
          sx={{
            fontSize: { xs: "16px", sm: "20px", md: "24px" },
            textAlign: { xs: "center", sm: "end" },
            marginRight: { xs: "0", sm: "25px" },
            fontWeight: 800,
            color: "#ff7323",
          }}
        >
          Primeira Regra,{" "}
          <span style={{ textDecoration: "underline" }}>
            'Não perder dinheiro.'
          </span>
        </Typography>
        <Typography
          variant="h4"
          textAlign={"end"}
          marginRight={"25px"}
          sx={{
            fontSize: { xs: "12px", sm: "18px" },
            textAlign: { xs: "center", sm: "end" },
            marginRight: { xs: "0", sm: "25px" },
            fontWeight: 800,
            color: "#ff7323",
          }}
        >
          Segunda Regra,{" "}
          <span style={{ fontStyle: "italic" }}>
            'Lembrar da Primeira Regra!'
          </span>
        </Typography>
      </Box>
      <Box
        className="blinks"
        display={"flex"}
        sx={{
          backgroundColor: "#FFF",
          borderRadius: "33px",
          height: "50px",
          boxShadow: 6,
          width: { xs: "90%", sm: "auto" },
          justifyContent: "center",
        }}
      >
        <Button
          onClick={() => handleScroll("form")}
          sx={{
            color: "#ff8266",
            borderRadius: "33px",
            fontSize: { xs: "16px", sm: "18px" },
            fontWeight: 800,
            width: { xs: "100%", sm: "auto" },
          }}
        >
          Solicitar Acesso
        </Button>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        width={"78%"}
        boxShadow={20}
        marginBottom={"10%"}
        borderRadius={"25px 25px 8px 8px"}
        sx={{
          width: { xs: "95%", sm: "78%" },
        }}
      >
        <img
          src={require("../../../assets/img_mainframe.png")}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "25px 25px 8px 8px",
          }}
          alt="MainFrame"
        />
      </Box>
    </Box>
  );
}
