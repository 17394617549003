import { Badge, Box, Tooltip } from "@mui/material";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

export default function NotificationHeader() {
    return (
        <Box sx={{ width: 'auto', height: 'auto', cursor: 'pointer', padding: '5px',':hover': { backgroundColor: '#80808030', borderRadius: '50%'} }}>
            <Tooltip title="Clique para visualizar suas notificações">
                <Badge variant="dot" overlap={"circular"} color="error">
                    <NotificationsNoneIcon fontSize={'large'} sx={{ color: '#FFF' }} />
                </Badge>
            </Tooltip>
        </Box>
    )
}