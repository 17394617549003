import { Box, List, Typography } from "@mui/material";
import ListItemComponent from "./ListItem.component";

export default function OrdersMargemCard({data}) {
    return (
        <Box sx={{ padding: "10px", width: '100%', borderRadius: '6px' }}>
            <Typography
                textAlign={"left"}
                fontWeight={700}
                fontSize={"22px"}
                color="black">
                Pedidos de venda
            </Typography>
            <List >
                <ListItemComponent
                    color={"GRAY"}
                    text={`margem acima de 10%`}
                    value={data?.acimaDez || 0} />
                <ListItemComponent
                    color={"GREEN"}
                    text={`margem entre 5% e 10%`}
                    value={data?.entreCincoEDez || 0} />
                <ListItemComponent
                    color={"BLUE"}
                    text={`margem entre 0% e 5%`}
                    value={data?.entreZeroECinco || 0} />
                <ListItemComponent
                    color={"RED"}
                    text={`margem menor que 0%`}
                    value={data?.menorQueZero || 0} />
            </List>
        </Box>
    )
} 