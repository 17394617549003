import { BarChart } from "@mui/x-charts/BarChart";
import {
  Box,
  Container,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { LineChart } from "@mui/x-charts";
import { useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoIcon from "@mui/icons-material/Info";
import Top5SkusTable from "../components/HomeComponents/TopSku.table";

function HomePage() {
  const [insights] = useState([
    {
      total_orders: 39,
      new_orders: 9,
      processing_orders: 0,
      cancelled_orders: 3,
    },
  ]);

  const listItemComponentOrders = (color, text, value) => {
    return (
      <ListItem>
        <ListItemAvatar>
          <CircleIcon
            fontSize="inherit"
            sx={{
              color,
              boxShadow: "6",
              borderRadius: "100%",
              backgroundColor: color,
              margin: "0 0 0 40%",
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box display={"flex"} gap={2}>
              <Typography width={"15px"}>{value}</Typography>
              <Typography>{text}</Typography>
            </Box>
          }
        />
      </ListItem>
    );
  };

  const exTabela = [
    {
      mkplace: "SHOPEE",
      venda: "1.090.161,11",
      equalmar: "59681,78",
      permar: "5,45%",
    },
    {
      mkplace: "MAGAZINE_LUIZA",
      venda: "646.460,55",
      equalmar: "37121,21",
      permar: "5,74%",
    },
    {
      mkplace: "MERCADO_LIVRE",
      venda: "500.167,78",
      equalmar: "15740,59",
      permar: "3,15%",
    },
    {
      mkplace: "SICREDI",
      venda: "124.225,62",
      equalmar: "7931,11",
      permar: "6,38%",
    },
    {
      mkplace: "AMAZON_GLOB...",
      venda: "104.175,05",
      equalmar: "4000,79",
      permar: "3,84%",
    },
    {
      mkplace: "WEB_CONTINEN...",
      venda: "82.371,58",
      equalmar: "4321,31",
      permar: "5,25%",
    },
    {
      mkplace: "SHOPHUB",
      venda: "20.216,18",
      equalmar: "1706,96",
      permar: "8,44%",
    },
    {
      mkplace: "LEROY_MERLIN",
      venda: "11.592,44",
      equalmar: "887,30",
      permar: "7,65%",
    },
  ];

  const dateRange = [
    {
      label: "7D",
      range: 7,
    },
    {
      label: "30D",
      range: 30,
    },
    {
      label: "3M",
      range: 90,
    },
    {
      label: "6M",
      range: 180,
    },
    {
      label: "12M",
      range: 360,
    },
  ];

  return (
    <Container
      sx={{
        py: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        rowGap: 3,
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          backgroundColor: "#FFF",
          borderRadius: "8px",
          width: "90%",
          display: "flex",
          gap: 4,
          padding: "20px",
          // boxShadow: '4',
          justifyContent: "center",
        }}
      >
        <Box sx={{ padding: "4px", margin: "auto" }}>
          <Typography
            textAlign={"left"}
            marginLeft={"10%"}
            fontWeight={500}
            fontSize={"20px"}
            color="black"
          >
            Pedidos de venda
          </Typography>
          <List>
            {listItemComponentOrders(
              "GRAY",
              `Total de vendas`,
              insights[0].total_orders
            )}
            {listItemComponentOrders("GREEN", `Novos`, insights[0].new_orders)}
            {listItemComponentOrders(
              "BLUE",
              `Em andamento`,
              insights[0].processing_orders
            )}
            {listItemComponentOrders(
              "RED",
              `Cancelados`,
              insights[0].cancelled_orders
            )}
          </List>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box
          sx={{
            width: "40%",
            padding: "4px",
            display: "grid",
            gridTemplateColumns: "40% 40%",
            gap: 4,
            justifyItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 1,
              width: "90%",
              borderRadius: "6px",
              boxShadow: "8",
              height: "90%",
              backgroundColor: "#ff8266d9",
              padding: "5px 20px 5px 20px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "sans-serif",
                fontWeight: 600,
                fontSize: "18px",
              }}
            >
              {"Ticket médio Hoje"}
            </Typography>
            <Divider orientation="horizontal" variant="middle" />
            <Box
              sx={{
                backgroundColor: "#e1816c4d",
                width: "100%",
                height: "100%",
                borderRadius: "4px",
                marginBottom: "4px",
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 1,
              width: "90%",
              borderRadius: "6px",
              boxShadow: "8",
              height: "90%",
              backgroundColor: "#ff8266d9",
              padding: "5px 20px 5px 20px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "sans-serif",
                fontWeight: 600,
                fontSize: "18px",
              }}
            >
              {"Pedidos para enviar"}
            </Typography>
            <Divider orientation="horizontal" variant="middle" />
            <Box
              sx={{
                backgroundColor: "#e1816c4d",
                width: "100%",
                height: "100%",
                borderRadius: "4px",
                marginBottom: "4px",
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 1,
              width: "90%",
              borderRadius: "6px",
              boxShadow: "8",
              height: "90%",
              backgroundColor: "#ff8266d9",
              padding: "5px 20px 5px 20px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "sans-serif",
                fontWeight: 600,
                fontSize: "18px",
              }}
            >
              {"Ticket médio Mês"}
            </Typography>
            <Divider orientation="horizontal" variant="middle" />
            <Box
              sx={{
                backgroundColor: "#e1816c4d",
                width: "100%",
                height: "100%",
                borderRadius: "4px",
                marginBottom: "4px",
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 1,
              width: "90%",
              borderRadius: "6px",
              boxShadow: "8",
              height: "90%",
              backgroundColor: "#ff8266d9",
              padding: "5px 20px 5px 20px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "sans-serif",
                fontWeight: 600,
                fontSize: "18px",
              }}
            >
              {"Pedidos Enviados"}
            </Typography>
            <Divider orientation="horizontal" variant="middle" />
            <Box
              sx={{
                backgroundColor: "#e1816c4d",
                width: "100%",
                height: "100%",
                borderRadius: "4px",
                marginBottom: "4px",
              }}
            ></Box>
          </Box>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box
          sx={{
            padding: "4px",
            margin: "auto",
            boxShadow: "12",
            borderRadius: "6px",
          }}
        >
          <List>
            <ListItem>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#8080801F",
                  padding: "8px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  columnGap: 3,
                }}
              >
                <Box
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  padding={"4px 0 4px 4px"}
                >
                  <Typography fontWeight={500} fontSize={14}>
                    {"Hoje (50) R$ 500"}
                  </Typography>
                  <Typography fontWeight={500} fontSize={14}>
                    {"Ontem (200) R$ 1000"}
                  </Typography>
                </Box>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Typography color="GREEN" fontWeight={700} fontSize={20}>
                  {"+50%"}
                </Typography>
              </Box>
            </ListItem>

            <ListItem>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#8080801F",
                  padding: "8px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  columnGap: 3,
                }}
              >
                <Box
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  padding={"4px 0 4px 4px"}
                >
                  <Typography fontWeight={500} fontSize={14}>
                    {"Mês Atual (50) R$ 500"}
                  </Typography>
                  <Typography fontWeight={500} fontSize={14}>
                    {"Mês Passado (200) R$ 1000"}
                  </Typography>
                </Box>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Typography color="RED" fontWeight={700} fontSize={20}>
                  {"-25%"}
                </Typography>
              </Box>
            </ListItem>

            <ListItem>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#8080801F",
                  padding: "8px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  columnGap: 3,
                }}
              >
                <Box
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  padding={"4px 0 4px 4px"}
                >
                  <Typography fontWeight={500} fontSize={14}>
                    {"Esse Ano (50) R$ 500"}
                  </Typography>
                  <Typography fontWeight={500} fontSize={14}>
                    {"Ano Passado (200) R$ 1000"}
                  </Typography>
                </Box>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Typography color="GREEN" fontWeight={700} fontSize={18}>
                  {"+10%"}
                </Typography>
              </Box>
            </ListItem>
          </List>
        </Box>
      </Box>

      {/* <Divider orientation="horizontal" variant="middle" flexItem /> */}

      <Box
        sx={{
          backgroundColor: "#FFF",
          borderRadius: "8px",
          width: "90%",
          display: "flex",
          gap: 4,
          padding: "20px",
          // boxShadow: '6',
          justifyContent: "center",
        }}
      >
        <Box sx={{ padding: "4px", margin: "auto" }}>
          <Typography
            textAlign={"left"}
            marginLeft={"10%"}
            fontWeight={500}
            fontSize={"20px"}
            color="black"
          >
            Pedidos de venda
          </Typography>
          <List>
            {listItemComponentOrders(
              "GRAY",
              `margem acima de 10%`,
              insights[0].total_orders
            )}
            {listItemComponentOrders(
              "GREEN",
              `margem entre 5% e 10%`,
              insights[0].new_orders
            )}
            {listItemComponentOrders(
              "BLUE",
              `margem entre 0% e 5%`,
              insights[0].processing_orders
            )}
            {listItemComponentOrders(
              "RED",
              `margem menor que 0%`,
              insights[0].cancelled_orders
            )}
          </List>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box
          sx={{
            width: "40%",
            padding: "4px",
            display: "grid",
            gridTemplateColumns: "40% 40%",
            gap: 4,
            justifyItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 1,
              width: "90%",
              borderRadius: "6px",
              boxShadow: "8",
              height: "90%",
              backgroundColor: "#ff8266d9",
              padding: "5px 20px 5px 20px",
            }}
          >
            <Typography sx={{ fontFamily: "sans-serif", fontWeight: 500 }}>
              {"Margem hoje"}
            </Typography>
            <Divider orientation="horizontal" variant="middle" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 1,
              width: "90%",
              borderRadius: "6px",
              boxShadow: "8",
              height: "90%",
              backgroundColor: "#ff8266d9",
              padding: "5px 20px 5px 20px",
            }}
          >
            <Typography sx={{ fontFamily: "sans-serif", fontWeight: 500 }}>
              {"Margem negativa hoje"}
            </Typography>
            <Divider orientation="horizontal" variant="middle" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 1,
              width: "90%",
              borderRadius: "6px",
              boxShadow: "8",
              height: "90%",
              backgroundColor: "#ff8266d9",
              padding: "5px 20px 5px 20px",
            }}
          >
            <Typography sx={{ fontFamily: "sans-serif", fontWeight: 500 }}>
              {"Margem mês"}
            </Typography>
            <Divider orientation="horizontal" variant="middle" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 1,
              width: "90%",
              borderRadius: "6px",
              boxShadow: "8",
              height: "90%",
              backgroundColor: "#ff8266d9",
              padding: "5px 20px 5px 20px",
            }}
          >
            <Typography sx={{ fontFamily: "sans-serif", fontWeight: 500 }}>
              {"Margem negativa mês"}
            </Typography>
            <Divider orientation="horizontal" variant="middle" />
          </Box>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box
          display={"flex"}
          maxHeight={false}
          sx={{ margin: "auto", boxShadow: "18", borderRadius: "12px" }}
        >
          <TableContainer
            sx={{ boxShadow: "none", width: "100%" }}
            component={Paper}
          >
            <Table size={"small"} aria-label="tabela">
              <TableHead>
                <TableRow>
                  <TableCell align="left">MKPLACE</TableCell>
                  <TableCell align="right">VENDA(+)</TableCell>
                  <TableCell align="right">=MAR</TableCell>
                  <TableCell align="right">MAR%</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exTabela.map((row) => (
                  <TableRow key={row.mkplace}>
                    <TableCell align="left" component="th" scope="row">
                      {row.mkplace}
                    </TableCell>
                    <TableCell align="right">{row.venda}</TableCell>
                    <TableCell align="right">{row.equalmar}</TableCell>
                    <TableCell align="right">{row.permar}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: 4,
          background: "#FFF",
          boxShadow: "4",
          width: "90%",
          height: "800px",
          padding: "20px",
          borderRadius: "4px",
        }}
      >
        <Box display={"flex"}>
          <Typography variant="h5" fontWeight={700}>{`Gráficos`}</Typography>
          <Box
            display={"flex"}
            sx={{
              width: "100%",
              columnGap: "24px",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Typography
              sx={{
                marginTop: "auto",
                fontSize: "14px",
                fontStyle: "italic",
                color: "gray",
                width: "auto",
              }}
            >{`Atualizado em 14/11/2024 as 11:33`}</Typography>
            <Box
              display={"flex"}
              sx={{
                boxShadow: 4,
                border: "1px solid #00000036",
                height: "24px",
                width: "auto",
                padding: "0 6px 0 6px",
                gap: "4px",
                borderRadius: "6px",
              }}
            >
              {dateRange.map((v) => (
                <>
                  <Typography
                    marginTop={"auto"}
                    marginBottom={"auto"}
                    fontSize="13px"
                    fontWeight={600}
                    key={v.label}
                    width={"12%"}
                    sx={{
                      padding: "0 2px 0 2px",
                      cursor: "pointer",
                      ":hover": { color: "#ff8266" },
                    }}
                  >
                    {v.label}
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                </>
              ))}
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"4px"}
                padding={"2px 4px 4px 4px"}
                sx={{ cursor: "pointer" }}
              >
                <EditCalendarIcon
                  sx={{ paddingTop: "2px" }}
                  fontSize="inherit"
                />
                <Typography
                  textAlign={"center"}
                  sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                  width={"100%"}
                  key={`Customizado`}
                >{` Customizado`}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          display={"grid"}
          gridTemplateColumns={"auto auto"}
          height={"100%"}
          rowGap={4}
          justifyItems={"center"}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              boxShadow: 8,
              backgroundColor: "#FFF",
              borderRadius: "8px",
              width: "90%",
            }}
          >
            <Box display={"flex"} alignItems={"flex-end"}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                rowGap={1}
                alignItems={"flex-start"}
                padding={"30px 0 0 30px"}
              >
                <Box display={"flex"} columnGap={1} alignItems={"center"}>
                  <Typography fontWeight={700} fontSize={"22px"}>
                    Ticket Médio
                  </Typography>
                  <InfoIcon fontSize="small" sx={{ color: "gray" }} />
                </Box>
                <Typography fontWeight={900}>R$ 233,27</Typography>
              </Box>
              <MoreVertIcon
                sx={{ marginLeft: "auto", padding: "20px", cursor: "pointer" }}
              />
            </Box>
            <LineChart
              grid={{ horizontal: true, vertical: false }}
              xAxis={[
                {
                  scaleType: "point",
                  data: [
                    "09/Nov",
                    "10/Nov",
                    "11/Nov",
                    "12/Nov",
                    "13/Nov",
                    "14/Nov",
                    "15/Nov",
                  ],
                  disableTicks: true,
                },
              ]}
              yAxis={[
                {
                  disableTicks: true,
                },
              ]}
              series={[
                {
                  area: true,
                  data: [180, 60, 140, 180, 230, 240, 180],
                  color: "#ff8266d9",
                },
              ]}
              sx={{
                maxWidth: "95%",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              boxShadow: 8,
              backgroundColor: "#FFF",
              borderRadius: "8px",
              width: "90%",
            }}
          >
            <Box display={"flex"} alignItems={"flex-end"}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                rowGap={1}
                alignItems={"flex-start"}
                padding={"30px 0 0 30px"}
              >
                <Box display={"flex"} columnGap={1} alignItems={"center"}>
                  <Typography fontWeight={700} fontSize={"22px"}>
                    Valor Faturado
                  </Typography>
                  <InfoIcon fontSize="small" sx={{ color: "gray" }} />
                </Box>
                <Typography fontWeight={900}>R$ 853.539,38</Typography>
              </Box>
              <MoreVertIcon
                sx={{ marginLeft: "auto", padding: "20px", cursor: "pointer" }}
              />
            </Box>
            <BarChart
              grid={{ horizontal: true, vertical: false }}
              borderRadius={6}
              xAxis={[
                {
                  scaleType: "band",
                  data: [
                    "09/Nov",
                    "10/Nov",
                    "11/Nov",
                    "12/Nov",
                    "13/Nov",
                    "14/Nov",
                    "15/Nov",
                  ],
                  categoryGapRatio: 0.6,
                  barGapRatio: 0.4,
                  tickPlacement: "middle",
                  disableTicks: true,
                },
              ]}
              yAxis={[
                {
                  valueFormatter: (value) =>
                    `$ ${Intl.NumberFormat("en", { notation: "compact" }).format(value)}`,
                  disableTicks: true,
                },
              ]}
              series={[
                {
                  data: [85000, 95000, 200000, 140000, 120000, 110000, 10000],
                  color: "#ff8266d9",
                },
              ]}
              sx={{
                maxWidth: "95%",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              boxShadow: 8,
              backgroundColor: "#FFF",
              borderRadius: "8px",
              width: "90%",
            }}
          >
            <Box display={"flex"} alignItems={"flex-end"}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                rowGap={1}
                alignItems={"flex-start"}
                padding={"30px 0 0 30px"}
              >
                <Box display={"flex"} columnGap={1} alignItems={"center"}>
                  <Typography fontWeight={700} fontSize={"22px"}>
                    Quantidade Pedidos de Venda
                  </Typography>
                  <InfoIcon fontSize="small" sx={{ color: "gray" }} />
                </Box>
                <Typography fontWeight={900}>3659 pedidos</Typography>
              </Box>
              <MoreVertIcon
                sx={{ marginLeft: "auto", padding: "20px", cursor: "pointer" }}
              />
            </Box>
            <BarChart
              grid={{ horizontal: true, vertical: false }}
              borderRadius={6}
              xAxis={[
                {
                  scaleType: "band",
                  data: [
                    "09/Nov",
                    "10/Nov",
                    "11/Nov",
                    "12/Nov",
                    "13/Nov",
                    "14/Nov",
                    "15/Nov",
                  ],
                  categoryGapRatio: 0.7,
                  barGapRatio: 0.4,
                  tickPlacement: "middle",
                  disableTicks: true,
                },
              ]}
              yAxis={[
                {
                  valueFormatter: (value) =>
                    `${Intl.NumberFormat("en", { notation: "compact" }).format(value)}`,
                  disableTicks: true,
                },
              ]}
              series={[
                {
                  data: [380, 410, 1110, 600, 480, 450, 10],
                  color: "#ff8266d9",
                },
              ]}
              sx={{
                maxWidth: "95%",
              }}
            />
          </Box>
          <Top5SkusTable />
        </Box>
      </Box>
    </Container>
  );
}

export default HomePage;
