import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { WhatsApp } from "@mui/icons-material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";
import MaskedInput from "../../components/MaskedInput";
import { useEffect, useState } from "react";
import { useCreateLead } from "../../../client/hooks/users/user.queries";

export default function ContactPage({ theme }) {
  const [alert, setAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [performSendLead, setPerformSendLead] = useState(false);
  const [lead, setLead] = useState({});
  const { data, isError, isLoading, refetch } = useCreateLead(
    lead,
    performSendLead
  );
  const [phone, setPhone] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (errors[name]) {
      setErrors({ ...errors, [name]: false });
    }
  };

  const handleSubmit = () => {
    const newErrors = {
      name: !formData.name.trim(),
      email: !formData.email.trim(),
    };

    setErrors(newErrors);

    if (!newErrors.name && !newErrors.email) {
      let led = { ...formData, phone };
      setLead(led);
      setTimeout(() => {
        if (!isError) setPerformSendLead(true);
        if (isError) refetch();
      }, 200);
      setFormData({
        name: "",
        email: "",
        message: "",
      });
      setPhone("");
    }
  };

  useEffect(() => {
    if (data && !isError) {
      setAlert(true);
      setSuccessMsg(
        "Obrigado pelo interesse! Em breve entraremos em contato com você."
      );
    }

    if (isError) {
      setAlert(true);
      setErrorMsg("Ocorreu um erro inesperado.");
      setAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isError, data]);

  return (
    <>
      <Snackbar
        autoHideDuration={5000}
        open={alert}
        onClose={() => setAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setAlert(false)}
          severity={isError ? "error" : "success"}
        >
          {isError ? errorMsg : successMsg}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          padding: "5% 10%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 8,
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="h2"
          fontWeight={800}
          textAlign="center"
          color="#FF7323"
          sx={{
            fontSize: { xs: "40px", sm: "60px", md: "70px" },
            marginBottom: 2,
          }}
        >
          Entre em Contato
        </Typography>
        <Typography
          variant="body1"
          textAlign="center"
          color="#555"
          sx={{
            fontSize: { xs: "16px", sm: "18px", md: "20px" },
            marginBottom: 4,
          }}
        >
          Vamos juntos automatizar seu negócio! Preencha o formulário abaixo ou
          entre em contato diretamente conosco.
        </Typography>

        <Box
          id="form"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            width: "100%",
            gap: 5,
          }}
        >
          <Box
            sx={{
              flex: 1,
              background: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
            }}
          >
            <TextField
              label="Nome"
              fullWidth
              variant="outlined"
              margin="normal"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              error={errors.name}
              helperText={errors.name && "O nome é obrigatório"}
              size="small"
            />
            <TextField
              label="Email"
              fullWidth
              variant="outlined"
              margin="normal"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              error={errors.email}
              helperText={errors.email && "O e-mail é obrigatório"}
              size="small"
            />
            <MaskedInput
              mask="(99) 99999-9999"
              label={"Telefone"}
              size="small"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Mensagem"
              fullWidth
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              size="small"
            />
            <Button
              type="submit"
              disabled={isLoading}
              onClick={handleSubmit}
              variant="contained"
              sx={{
                marginTop: 2,
                width: "100%",
                backgroundColor: "#FF7323",
                color: "white",
                "&:hover": {
                  backgroundColor: "#FF961C",
                },
              }}
            >
              Enviar Mensagem
            </Button>
          </Box>

          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: 4,
            }}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <EmailIcon sx={{ color: "#FF7323", fontSize: 30 }} />
              <Typography variant="body1" color="#555">
                jonas@walaplace.com.br
              </Typography>
            </Box>
            <Box
              className="blinks"
              sx={{
                cursor: "pointer",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "#FAC2A1",
                  color: "#fff",
                  transition: "all 0.3s ease",
                },
              }}
            >
              <a
                href="https://wa.me/5551997971635?text=Quero%20saber%20sobre%20o%20wala%20smart%20work"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  gap: 15,
                }}
              >
                <WhatsApp sx={{ color: "#FF7323", fontSize: 30 }} />
                <Typography variant="body1" color="#555">
                  Conversar no WhatsApp
                </Typography>
              </a>
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <PhoneIcon sx={{ color: "#FF7323", fontSize: 30 }} />
              <Typography variant="body1" color="#555">
                (51) 9 97971635
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <LocationOnIcon sx={{ color: "#FF7323", fontSize: 30 }} />
              <Typography variant="body1" color="#555">
                Rua Carlos Maurício Werlang 288 Santa Cruz do Sul - RS
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "center",
            marginTop: 4,
          }}
        >
          <a
            href="https://www.instagram.com/wala_smartwork/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: 15,
            }}
          >
            <IconButton
              sx={{
                backgroundColor: "#FF7323",
                color: "white",
                "&:hover": {
                  backgroundColor: "#FF961C",
                },
              }}
            >
              <InstagramIcon />
            </IconButton>
          </a>
          <a
            href="https://www.linkedin.com/company/wala-smart-work/about/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: 15,
            }}
          >
            <IconButton
              sx={{
                backgroundColor: "#FF7323",
                color: "white",
                "&:hover": {
                  backgroundColor: "#FF961C",
                },
              }}
            >
              <LinkedInIcon />
            </IconButton>
          </a>
          <a
            href="https://www.linkedin.com/company/wala-smart-work/about/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: 15,
            }}
          >
            <IconButton
              sx={{
                backgroundColor: "#FF7323",
                color: "white",
                "&:hover": {
                  backgroundColor: "#FF961C",
                },
              }}
            >
              <LanguageIcon />
            </IconButton>
          </a>
        </Box>
      </Box>
    </>
  );
}
