import { useQuery } from "@tanstack/react-query";
import { api } from "../../config";

 function useGetFilters(enabled) {
    return useQuery({
        queryFn: async () => {
            const res = await api.get(`/marketplace/filters`);
            return res.data;
        },
        queryKey: ['order-filters'],
        enabled,
        retry: false,
        refetchOnWindowFocus: false
    })
}


export {
    useGetFilters,
}