import { Table, Checkbox, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, IconButton, Paper, Box, Collapse, TablePagination, TableSortLabel, Button, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TablePaginationActions from "../../components/TablePaginationAction";
import { useEffect, useRef, useState } from "react";
import moment from "moment/moment";
import { exportStyledTableToXLSX } from "../utils/ExportTable";

const OrdersTable = ({ queryData, page, setPage, refetch, setSnackbarMessage, setOpenSnackbar, isRefetching, isLoading }) => {

    const tableContainerRef = useRef(null);
    const [expandedRow, setExpandedRow] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [data, setData] = useState({});

    const handleExport = () => {
        exportStyledTableToXLSX(data?.data, selectedRows);
    };

    useEffect(() => {
        setData(queryData);
    }, [queryData])

    const handleSort = (key) => {
        const sorted = [...queryData.data].sort((a, b) => {
            let valueA = a;
            let valueB = b;
    
            const keys = key.split('.');
            keys.forEach(k => {
                valueA = valueA[k] ?? null;
                valueB = valueB[k] ?? null;
            });
    
            if (valueA === null && valueB !== null) return sortConfig.direction === 'asc' ? 1 : -1;
            if (valueB === null && valueA !== null) return sortConfig.direction === 'asc' ? -1 : 1;
            if (valueA === null && valueB === null) return 0;
    
            const parseToDate = (value) => {
                if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/)) {
                    const date = new Date(value);
                    return isNaN(date.getTime()) ? null : date;
                }
                return null;
            };
    
            const dateA = parseToDate(valueA);
            const dateB = parseToDate(valueB);
    
            if (dateA && dateB) {
                return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
            } else if (dateA && !dateB) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            } else if (!dateA && dateB) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
    
            const parseToNumber = (value) => {
                if (typeof value === "string") {
                    const parsedValue = value.replace(/[^\d.-]/g, '');
                    const number = parseFloat(parsedValue);
                    return isNaN(number) ? value : number;
                }
                return value;
            };
    
            const parsedA = parseToNumber(valueA);
            const parsedB = parseToNumber(valueB);
    
            if (typeof parsedA === 'number' && typeof parsedB === 'number') {
                return sortConfig.direction === 'asc' ? parsedA - parsedB : parsedB - parsedA;
            }
    
            if (Array.isArray(valueA) && Array.isArray(valueB)) {
                const lengthA = valueA.length;
                const lengthB = valueB.length;
                return sortConfig.direction === 'asc' ? lengthA - lengthB : lengthB - lengthA;
            }
    
            if (typeof valueA === 'string' && typeof valueB === 'string') {
                return sortConfig.direction === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            }
    
            return 0;
        });
    
        setData({ ...queryData, data: sorted });
        setSortConfig({
            key,
            direction: sortConfig.direction === 'asc' ? 'desc' : 'asc',
        });
    };

    const handleCopy = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);

        setSnackbarMessage('Texto copiado!');
        setOpenSnackbar(true);
    };

    function parseReducedTitle(value, total = 22) {
        if (value.length > total) {
            value = value.substring(0, total) + "...";
            return value;
        }
        return value;
    }

    const handleChangePage = (event, newPage) => {
        setPage({ page_index: newPage, page_count: page.page_count });
        setExpandedRow(null);
        setTimeout(() => {
            refetch();
            if (tableContainerRef.current) {
                tableContainerRef.current.scrollTop = 0;
            }
        }, 100);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage({ page_count: parseInt(event.target.value, 10), page_index: 0 });
        setExpandedRow(null);
        setTimeout(() => {
            refetch();
            if (tableContainerRef.current) {
                tableContainerRef.current.scrollTop = 0;
            }
        }, 100);
    };

    const parseSKUs = (arr) => {
        return arr.length > 1 ? `${parseReducedTitle(arr[0].sku.partnerId, 12)}.` : parseReducedTitle(arr[0].sku.partnerId, 12);
    };

    const parseToCel = (value) => {
        let result = `R$ ${value}`;
        if (!result.includes('.')) {
            result = `R$ ${value}.00`;
        }
        if (result.split('.')[1].length === 1) {
            result = result.concat('0');
        }
        return result.replace('.', ',');
    };

    const handleRowSelect = (rowIndex) => {
        if (selectedRows.includes(rowIndex)) {
            setSelectedRows(selectedRows.filter(index => index !== rowIndex));
        } else {
            setSelectedRows([...selectedRows, rowIndex]);
        }
    };

    // Handle select all
    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedRows(data?.data?.map((_, index) => index) || []);
        } else {
            setSelectedRows([]);
        }
    };

    const handleRowClick = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    const allRowsSelected = data?.data?.length > 0 && selectedRows.length === data?.data?.length;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 125px)',
            overflow: 'hidden',
        }}>
            <TableContainer ref={tableContainerRef} component={Paper} sx={{
                cursor: 'default',
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                overflowY: 'auto',
            }}>
                <Table aria-label="orders table">
                    <TableHead>
                        <TableRow sx={{
                            backgroundColor: '#2c3e50',
                            color: 'white',
                            position: 'sticky',
                            top: 0,
                            zIndex: 2,
                            height: '56px',
                        }}>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    size="small"
                                    checked={allRowsSelected}
                                    indeterminate={selectedRows.length > 0 && !allRowsSelected}
                                    onChange={handleSelectAll}
                                />
                            </TableCell>
                            <TableCell sx={{ ...headerCellStyle, width: '15px' }}></TableCell>
                            <TableCell align="left" sx={{...headerCellStyle, width: '60px', maxWidth: '60px'}}>
                                <TableSortLabel
                                    sx={{
                                        '&.Mui-active': { color: '#FFF' },
                                        '& .MuiTableSortLabel-icon': { color: '#FFF !important', fontSize: '14px' }, ':hover': { color: '#FFF' }
                                    }}
                                    active={sortConfig.key === 'partnerId'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('partnerId')}>
                                    PEDIDO
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={headerCellStyle}>
                                <TableSortLabel
                                    sx={{
                                        '&.Mui-active': { color: '#FFF' },
                                        '& .MuiTableSortLabel-icon': { color: '#FFF !important', fontSize: '14px' }, ':hover': { color: '#FFF' }
                                    }}
                                    active={sortConfig.key === 'items'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('items')}>
                                    ITENS
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={headerCellStyle}>
                                <TableSortLabel
                                    sx={{
                                        '&.Mui-active': { color: '#FFF' },
                                        '& .MuiTableSortLabel-icon': { color: '#FFF !important', fontSize: '14px' }, ':hover': { color: '#FFF' }
                                    }}
                                    active={sortConfig.key === 'marketPlace'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('marketPlace')}>
                                    MARKETPLACE
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={headerCellStyle}>
                                <TableSortLabel
                                    sx={{
                                        '&.Mui-active': { color: '#FFF' },
                                        '& .MuiTableSortLabel-icon': { color: '#FFF !important', fontSize: '14px' }, ':hover': { color: '#FFF' }
                                    }}
                                    active={sortConfig.key === 'status'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('status')}>
                                    STATUS
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={headerCellStyle}>
                                <TableSortLabel
                                    sx={{
                                        '&.Mui-active': { color: '#FFF' },
                                        '& .MuiTableSortLabel-icon': { color: '#FFF !important', fontSize: '14px' }, ':hover': { color: '#FFF' }
                                    }}
                                    active={sortConfig.key === 'marketPlaceId'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('marketPlaceId')}>
                                    <Tooltip title={'PEDIDO LOJA (MARKETPLACE ID)'} arrow>
                                        <span style={{
                                            maxWidth: '60px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}>PEDIDO-LOJA</span>
                                    </Tooltip>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{
                                ...headerCellStyle
                            }}>
                                <TableSortLabel
                                    sx={{
                                        '&.Mui-active': { color: '#FFF' },
                                        '& .MuiTableSortLabel-icon': { color: '#FFF !important', fontSize: '14px' }, ':hover': { color: '#FFF' }
                                    }}
                                    active={sortConfig.key === 'total'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('total')}>
                                    <Tooltip title={'TOTAL DA VENDA'} arrow>
                                        <span style={{
                                            maxWidth: '60px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}>TOTAL DA VENDA</span>
                                    </Tooltip>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="left" sx={headerCellStyle}>DESC</TableCell>
                            <TableCell align="left" sx={headerCellStyle}>FP(-)</TableCell>
                            <TableCell align="left" sx={{
                                ...headerCellStyle,
                                maxWidth: '35px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>
                                <Tooltip title={'TOTAL DO PRODUTO'} arrow>
                                    <span>TOTAL DO PRODUTO</span>
                                </Tooltip>
                            </TableCell>
                            <TableCell align="left" sx={headerCellStyle}>FG(-)</TableCell>
                            <TableCell align="left" sx={{ ...headerCellStyle, textAlign: 'center' }}>CMV(-)</TableCell>
                            <TableCell align="left" sx={{ ...headerCellStyle, textAlign: 'center' }}>COMIS(-)</TableCell>
                            <TableCell align="left" sx={{ ...headerCellStyle, textAlign: 'center' }}>IMP(-)</TableCell>
                            <TableCell align="left" sx={{ ...headerCellStyle, textAlign: 'center' }}>VPC(+)</TableCell>
                            <TableCell align="left" sx={{ ...headerCellStyle, textAlign: 'center' }}>RMB(+)</TableCell>
                            <TableCell align="left" sx={{ ...headerCellStyle, textAlign: 'center' }}>TX FIXA(-)</TableCell>
                            <TableCell align="left" sx={{ ...headerCellStyle, textAlign: 'center' }}>=MAR</TableCell>
                            <TableCell align="left" sx={{ ...headerCellStyle, textAlign: 'center' }}>MAR%</TableCell>
                            <TableCell align="left" sx={headerCellStyle}>
                                <TableSortLabel
                                    sx={{
                                        '&.Mui-active': { color: '#FFF' },
                                        '& .MuiTableSortLabel-icon': { color: '#FFF !important', fontSize: '14px' }, ':hover': { color: '#FFF' }
                                    }}
                                    active={sortConfig.key === 'shipping.state'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('shipping.state')}>
                                    <span>UF</span>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="left" sx={headerCellStyle}>
                                <TableSortLabel
                                    sx={{
                                        '&.Mui-active': { color: '#FFF' },
                                        '& .MuiTableSortLabel-icon': { color: '#FFF !important', fontSize: '14px' }, ':hover': { color: '#FFF' }
                                    }}
                                    active={sortConfig.key === 'createdAt'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('createdAt')}>
                                    <Tooltip title={'DATA DE CRIAÇÃO DO PEDIDO'} arrow>
                                        <span style={{
                                            maxWidth: '60px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}>DATA DE CRIAÇÃO DO PEDIDO</span>
                                    </Tooltip>
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.data?.map((value, index) => (
                            <>
                                <TableRow key={index} sx={{
                                    backgroundColor: index % 2 === 0 ? '#ffffff' : '#f2f2f2',
                                }}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            size="small"
                                            checked={selectedRows.includes(index)}
                                            onChange={() => handleRowSelect(index)}
                                        />
                                    </TableCell>
                                    <TableCell onClick={() => handleRowClick(index)} sx={cellStyle}>
                                        <IconButton size="small">
                                            {expandedRow === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell sx={{ ...cellStyle, width: '20px', maxWidth: '20px', textAlign: 'left' }}>{value?.partnerId}</TableCell>
                                    <TableCell sx={{ ...cellStyle, width: '30px', textAlign: 'left', paddingLeft: '15px' }}>{value?.items.length}</TableCell>
                                    <TableCell sx={{ ...cellStyle, maxWidth: '30px', width: '30px' }}>
                                        <Tooltip title={value?.marketPlace} arrow>
                                            <span onClick={() => handleCopy(value?.marketPlace)}>{value?.marketPlace}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ ...cellStyle, maxWidth: '20px', width: '20px' }}>
                                        <Tooltip title={value?.status} arrow>
                                            <span onClick={() => handleCopy(value?.status)}>{value?.status}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ ...cellStyle, maxWidth: '60px', width: '60px' }}>
                                        <Tooltip title={value?.marketPlaceId} arrow>
                                            <span onClick={() => handleCopy(value?.marketPlaceId)}>
                                                {parseReducedTitle(value?.marketPlaceId)}
                                            </span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell onClick={() => handleCopy(value?.total)} sx={{ ...cellStyle, color: 'blue', width: '60px' }}>{parseToCel(value?.total)}</TableCell>
                                    <TableCell sx={cellStyle}>
                                        <span onClick={() => handleCopy(value?.discount)}>{parseToCel(value?.discount)}</span>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Tooltip title={`Custo de Frete: ${parseToCel(value?.freight)}`} arrow>
                                            <span onClick={() => handleCopy(value?.freight)}>{parseToCel(value?.freight)}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ ...cellStyle, color: 'blue', width: '40px' }}>
                                        <Tooltip title={`Total Produto: ${parseToCel(value?.totalProduto?.toFixed(2))}`} arrow>
                                            <span onClick={() => handleCopy(value?.totalProduto?.toFixed(2))}>{parseToCel(value?.totalProduto?.toFixed(2))}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Tooltip title={`Frete Gratis: ${parseToCel(value?.sellerFreight)}`} arrow>
                                            <span onClick={() => handleCopy(value?.sellerFreight)}>{parseToCel(value?.sellerFreight)}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ ...cellStyle, color: 'red', textAlign: 'center' }}>
                                        <span onClick={() => handleCopy(value?.totalCvm?.toFixed(2))}>{parseToCel(value?.totalCvm?.toFixed(2))}</span>
                                    </TableCell>
                                    <TableCell sx={{ ...cellStyle, color: 'red', textAlign: 'center' }}>
                                        <span onClick={() => handleCopy(value?.comission || 0)}>{parseToCel(value?.comission || 0)}</span>
                                    </TableCell>
                                    <TableCell sx={{ ...cellStyle, color: 'red', textAlign: 'center' }}>
                                        <Tooltip title={
                                            <Box display={'flex'} flexDirection={'column'}>
                                                <span>PIS Cofins: {parseToCel(value?.totalImpPisCofins?.toFixed(2))}</span>
                                                <span>ICMS Difal: {parseToCel(value?.icmsDifal?.toFixed(2) || 0)}</span>
                                                <span>ICMS Sku: {parseToCel((value?.items?.map((item) => item.icms).reduce((ac, at) => ac + at, 0) || 0).toFixed(2))}</span>
                                            </Box>
                                        } arrow>
                                            <span onClick={() => handleCopy("IMP(-)")}>{parseToCel(value?.impostoTotal?.toFixed(2))}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ ...cellStyle, textAlign: 'center' }}>
                                        <span onClick={() => handleCopy("VPC(+)")}>{parseToCel(0)}</span>
                                    </TableCell>
                                    <TableCell sx={{ ...cellStyle, textAlign: 'center' }}>
                                        <span onClick={() => handleCopy("RMB(+)")}>{parseToCel(0)}</span>
                                    </TableCell>
                                    <TableCell sx={{ ...cellStyle, textAlign: 'center' }}>
                                        <span onClick={() => handleCopy("TX FIXA")}>{parseToCel(0)}</span>
                                    </TableCell>
                                    <TableCell sx={{ ...cellStyle, 
                                        color: value?.percentMar > 0 ? value?.percentMar < 5 ? 'black' : value?.percentMar > 10 ? 'blue' :'blue' : 'red', 
                                        background: value?.percentMar > 0 ? value?.percentMar < 5 ? '#f2cea7' : value?.percentMar > 10 ? '#deeeff' :'#caf5c1' : '#efb5b9'
                                        , textAlign: 'center' }}>
                                        <span onClick={() => handleCopy(value?.equalMar)}>{parseToCel(value?.equalMar?.toFixed(2))}</span>
                                    </TableCell>
                                    <TableCell sx={{ ...cellStyle,
                                        color: value?.percentMar > 0 ? value?.percentMar < 5 ? 'black' : value?.percentMar > 10 ? 'blue' :'blue' : 'red', 
                                        background: value?.percentMar > 0 ? value?.percentMar < 5 ? '#f2cea7' : value?.percentMar > 10 ? '#deeeff' :'#caf5c1' : '#efb5b9',
                                        textAlign: 'center' }}>
                                        <span onClick={() => handleCopy(value?.percentMar)}>{value?.percentMar?.toFixed(2)}%</span>
                                    </TableCell>
                                    <TableCell sx={{ ...cellStyle, textAlign: 'left', width: '40px', maxWidth: '40px' }}>
                                        <span onClick={() => handleCopy(value?.shipping?.state)}>{value?.shipping?.state}</span>
                                    </TableCell>
                                    <TableCell sx={{ ...cellStyle, textAlign: 'left', width: '80px', maxWidth: '80px' }}>
                                        <Tooltip title={`Data da Criação: ${moment(value?.createdAt).format('DD/MM/YYYY HH:mm')}`} arrow>
                                            <span onClick={() => handleCopy(moment(value?.createdAt).format('DD/MM/YYYY HH:mm:ss'))}>{moment(value?.createdAt).format('DD/MM/YYYY HH:mm:ss')}</span>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                                {expandedRow === index && (
                                    <TableRow>
                                        <TableCell colSpan={18}>
                                            <Collapse
                                                in={expandedRow === index}
                                                timeout={{ enter: 300, exit: 300 }}
                                                sx={{
                                                    animation: expandedRow === index
                                                        ? 'fadeIn 300ms ease-in-out'
                                                        : 'fadeOut 300ms ease-in-out',
                                                    '@keyframes fadeIn': {
                                                        from: { opacity: 0, transform: 'translateY(-10px)' },
                                                        to: { opacity: 1, transform: 'translateY(0)' },
                                                    },
                                                    '@keyframes fadeOut': {
                                                        from: { opacity: 1, transform: 'translateY(0)' },
                                                        to: { opacity: 0, transform: 'translateY(-10px)' },
                                                    },
                                                    padding: 2,
                                                    width: '100%',
                                                    backgroundColor: '#f9f9f9',
                                                    borderRadius: '5px',
                                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                                }}
                                            >
                                                <Box display={'flex'} justifyContent={'center'} justifyItems={'center'}>
                                                    <Table sx={{ width: '100%', margin: 'auto' }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>SKU</TableCell>
                                                                <TableCell>Produto</TableCell>
                                                                <TableCell>Quantidade</TableCell>
                                                                <TableCell>Valor Unitário</TableCell>
                                                                <TableCell>Valor Bruto</TableCell>
                                                                <TableCell>Desconto Produto</TableCell>
                                                                <TableCell>CMV</TableCell>
                                                                <TableCell>ICMS Sku</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {value.items?.map((item, idx) => (
                                                                <TableRow key={idx}>
                                                                    <TableCell>{item.sku.partnerId}</TableCell>
                                                                    <TableCell>{item.sku.title}</TableCell>
                                                                    <TableCell>{item.amount}</TableCell>
                                                                    <TableCell>{`${parseToCel(item.unit.toFixed(2))}`}</TableCell>
                                                                    <TableCell>{`${parseToCel(item.gross.toFixed(2))}`}</TableCell>
                                                                    <TableCell>{`${parseToCel(item.discount.toFixed(2))}`}</TableCell>
                                                                    <TableCell>{`${parseToCel(item?.calculatedCvm?.toFixed(2) || 0)}`}</TableCell>
                                                                    <TableCell>{`${parseToCel(item?.icms?.toFixed(2) || 0)}`}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{
                backgroundColor: '#ffffff',
                borderTop: '1px solid #dcdcdc',
                boxShadow: '0px -2px 4px rgba(0,0,0,0.1)',
                display: 'flex',
                justifyContent: 'flex-end'
            }}>
                <Button sx={{ 
                    margin: 'auto auto auto 15px', 
                    textAlign: 'center', 
                    fontSize: '12px', 
                    fontWeight: 600, 
                    height: '30px', 
                    borderRadius: '4px', 
                    border: '1px solid'
                    }} onClick={handleExport}>EXPORTAR</Button>
                <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    colSpan={3}
                    count={data?.total || 0}
                    rowsPerPage={page.page_count}
                    page={page.page_index}
                    SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    disabled={isRefetching || isLoading}
                />
            </Box>
        </Box>
    )
};


const headerCellStyle = {
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#ffffff',
    textAlign: 'left',
    backgroundColor: '#34495e',
    padding: '8px',
};

const cellStyle = {
    fontSize: '12px',
    padding: '8px',
    color: '#2c3e50',
    cursor: 'pointer',
    textAlign: 'left',
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:hover': {
        backgroundColor: '#ecf0f1',
    },
};

export default OrdersTable;
