import { useQuery } from "@tanstack/react-query";
import { api } from "../../config";

 function useGetUser() {
    return useQuery({
        queryFn: async () => {
            const res = await api.get(`/user`);
            return res.data;
        },
        queryKey: ['user-unique'],
        retry: false,
        refetchOnWindowFocus: false
    })
}

function usePutUser(data, exec) {
    return useQuery({
        queryFn: async () => {
            const res = await api.put(`/user`, data);
            return res.data;
        },
        queryKey: ['user-update'],
        enabled: exec,
        retry: false,
        refetchOnWindowFocus: false
    })
}

 function useGetAffiliates(exec) {
    return useQuery({
        queryFn: async () => {
            const res = await api.get(`/user/affiliates`);
            return res.data;
        },
        queryKey: ['users-list'],
        enabled: exec,
        retry: false,
        refetchOnWindowFocus: false
    })
}
 function useCreatetUserAffiliate(newUser,exec) {
    return useQuery({
        queryFn: async () => {
            const res = await api.post(`/user` , newUser);
            return res.data;
        },
        queryKey: ['users-create-affiliate'],
        enabled: exec,
        retry: false,
        refetchOnWindowFocus: false
    })
}

 function useUpdateUserAffiliate(data, id, exec) {
    return useQuery({
        queryFn: async () => {
            const res = await api.put(`/user/affiliates/${id}`, data);
            return res.data;
        },
        queryKey: ['users-update-affiliate'],
        enabled: exec,
        retry: false,
        refetchOnWindowFocus: false
    })
}

function useCreateLead(newLead,exec) {
    return useQuery({
        queryFn: async () => {
            const res = await api.post(`/user/contact` , newLead);
            return res.data;
        },
        queryKey: ['users-create-affiliate'],
        enabled: exec,
        retry: false,
        refetchOnWindowFocus: false
    })
}



export {
    useGetUser,
    useGetAffiliates,
    usePutUser,
    useCreatetUserAffiliate,
    useUpdateUserAffiliate,
    useCreateLead
}