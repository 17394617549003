import { Box, createTheme, ThemeProvider, Snackbar, Slide, Typography, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetOrders } from "../../client/hooks/orders/order.queries";
import { ptBR } from '@mui/material/locale';
import TableFilter from "./components/TableFilter.component";
import OrdersTable from "./components/OrdersTable.component";
import moment from "moment/moment";
import SellOrdersCard from "./components/SellOrders.component";
import OrdersMargemCard from "./components/OrdersMargem.component";
import { LineChart } from "@mui/x-charts";
import OrderMargemSection from "./components/OrderMainMargem.component";

export default function OrdersPage() {
    const [page, setPage] = useState({ page_count: 20, page_index: 0 });
    const [canFetch, setCanFetch] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem('paged_orders_filter')) || {
        category: null,
        status: null,
        dateFrom: null,
        dateTo: null,
        sku: [],
        brand: null,
        marketplace: null,
    });
    const { data, isLoading, isRefetching, refetch } = useGetOrders({
        page_index: page.page_index,
        page_count: page.page_count,
        sku: ((filters?.sku?.length > 0) && filters?.sku?.join(',')) || null,
        category: filters?.category?.id,
        status: filters?.status,
        brand: filters?.brand?.id,
        marketplace: filters?.marketplace,
        dateTo: (filters.dateTo && moment(filters.dateTo, "DD/MM/YYYY").format("YYYY-MM-DD")) || null,
        dateFrom: (filters.dateFrom && moment(filters.dateFrom, "DD/MM/YYYY").format("YYYY-MM-DD")) || null,
    }, canFetch);

    useEffect(() => {
        localStorage.setItem('paged_orders_filter', JSON.stringify(filters));
    }, [filters]);

    useEffect(() => {
        setCanFetch(data ? false : true);
    }, [data]);


    function SlideTransition(props) {
        return <Slide {...props} direction="left" />;
    }

    return (
        <ThemeProvider theme={createTheme({}, ptBR)}>
            <Box sx={{
                backgroundColor: '#f9f9f9',
                width: '100%',
                margin: 'auto',
                borderRadius: '12px',
                boxShadow: 8,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <TableFilter filters={filters} setFilters={setFilters} refetch={refetch} />
                <OrderMargemSection />
                <OrdersTable
                    queryData={data}
                    page={page}
                    setPage={setPage}
                    refetch={refetch}
                    setSnackbarMessage={setSnackbarMessage}
                    setOpenSnackbar={setOpenSnackbar}
                    isLoading={isLoading}
                    isRefetching={isRefetching}
                />
                <Snackbar
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={() => setOpenSnackbar(false)}
                    message={snackbarMessage}
                    TransitionComponent={SlideTransition}
                />
            </Box>
        </ThemeProvider>
    );
}
